import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Grid from '@material-ui/core/Grid';
import VodafoneZiggoLogo from '../../images/brands/vfzg-logo.svg';
import ReturnArrow from '../../images/return-arrow.svg';

const VodafoneZiggo = () => (
  <Layout>
    <Grid container
          className="link-list"
    >
      <Grid item xs={4}>
        <div>
          <Link to="/projects/"><img src={ ReturnArrow } className="return-arrow" width='30px' alt="Return Logo" /></Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <Link to="/about/">About</Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <Link to="/contact/">Contact</Link>
        </div>
      </Grid>
    </Grid>
      <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={8}>
        <div className='app-intro'>
          <Link to="projects/">Projects</Link>
          <Grid item xs={12}>
                 <span>
              <Link className="arrow" to="/projects/actionpact">&#x2039; </Link>
            </span>
            <h2 className="inline"> VodafoneZiggo </h2>
            <span >
              <Link className="arrow" to="/projects/vodafone">&#x203A;</Link>
            </span>
          </Grid>
          <img src={ VodafoneZiggoLogo } className="project-detail"  alt="Skills" />
          <Grid container
                direction="row"
                justify="center"
                className="about-content">
            <Grid item md={6}>
              <h3>Summary</h3>
              <p>Redesign, new features, bugfixes and many A/B-tests for the Vodafone E-Shop (Product and Checkout pages)</p>
              <p>Project combined checkout: new white labeled checkout (from scratch) for both  Ziggo and Vodafone.</p>
            </Grid>
            <Grid item md={6}>
              <h3>Stack</h3>
              <h4>Vodafone E-shop</h4>
              <p className="subcontent">AngularJS, Jquery, Grunt, Less, NodeJS and Handlebars.</p>
              <h4>White labeled checkout</h4>
              <p className="subcontent">React, Redux, styled Components, webpack, NodeJS</p>
              <h3>Period</h3>
              <p className="subcontent">2017 - present</p>
              <h3>URL</h3>
              <a className="white" href='https://www.vodafone.nl/shop/mobiel/telefoon' rel="noopener noreferrer" target="_blank" alt="vodafone eshop"> <h4>Vodafone E-shop</h4></a>
              <a className="white" href='https://shop.ziggo.nl/direct-bestellen/?subscriptionSku=4100684&deviceSku=107005931' rel="noopener noreferrer"  target="_blank" alt="Ziggo checkout"> <h4>White labeled checkout (Ziggo) </h4></a>
            </Grid>
          </Grid>
        </div>
      </Grid>
  </Layout>
)

export default VodafoneZiggo;
